import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import jsQR from "jsqr";
import DinamicaPresencialService from "../../services/DinamicaPresencialService";
import CampanhaService from "../../services/CampanhaService";
import AgendaService from "../../services/AgendaService";
import { useLocation } from "react-router-dom";
import WeexModal from "../../comps/weexModais/WeexModal";

export default function LeitorQrCodeDinamicaPresencial() {
  const dinamicaPresencialService = new DinamicaPresencialService();
  const campanhaService = new CampanhaService();
  const agendaService = new AgendaService();
  const navigate = useNavigate();
  let location = useLocation();
  const i18n = useStore().getState().i18n;

  const campanhaCorrente = campanhaService?.campanhaCorrente();
  const campaignCode = campanhaCorrente?.code;

  const qrCodePresencialHost = process.env.REACT_APP_HOST_QR_CODE_PRESENCIAL;

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [videoPronto, setVideoPronto] = useState(false);
  const [processandoQRCode, setProcessandoQRCode] = useState(false);
  const [erro, setErro] = useState(null);
  let { codigo, codigoAtividade } = useParams();
  const usarGeolocalizacao =
    location?.state?.dinamicaPresencial?.useGeolocation;

  const videoStyle = {
    width: "90vw",
  };

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const callbackModal = () => {
    setErro(null);
    navigate("/atividades");
  };

  const finalizarAcao = (atividade) => {
    if (atividade) {
      agendaService.atualizarAtividadeAgendaExecutada(codigoAtividade, true);
      navigate(`/missionFinished/${codigo}/PRESENCIAL/${codigoAtividade}`, {
        state: {
          atividade: atividade,
          dinamicaPresencial: location.state.dinamicaPresencial,
          nomeDia: location.state.nomeDia,
          codigo,
          earnedPoints: atividade.earnedPoints,
        },
      });
    }
  };

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const isValidUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  function checkUrl(value) {
    try {
      // eslint-disable-next-line max-len
      const url = new URL(value);
      if (
        url?.href.startsWith(qrCodePresencialHost) &&
        url?.href.includes("/registrar-presenca")
      ) {
        setErro({
          titulo: i18n.message(
            "dinamica.erro.finalizar.titulo",
            "Erro ao finalizar atividade",
          ),
          mensagem: i18n.message(
            "dinamica.presencial.checkin.url.indisponivel",
            "URL indisponível para a versão 2 do aplicativo.",
          ),
          voltarAgenda: false,
        });
        return;
      } else {
        setErro({
          titulo: i18n.message(
            "dinamica.erro.finalizar.titulo",
            "Erro ao finalizar atividade",
          ),
          mensagem: i18n.message(
            "dinamica.presencial.checkin.url.terceiros",
            "URL não é fornecido pela Weex",
          ),
          voltarAgenda: false,
        });
        return;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  const tick = () => {
    if (
      videoRef &&
      videoRef.current &&
      videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA
    ) {
      let video = videoRef.current;
      let canvasElement = canvasRef.current;
      let canvas = canvasElement.getContext("2d");
      canvasElement.hidden = false;

      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      const imageData = canvas.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height,
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      if (code && !processandoQRCode) {
        setProcessandoQRCode(true);
        if (!isValidJson(code.data)) {
          if (!isValidUrl(code.data)) {
            setErro({
              titulo: i18n.message(
                "dinamica.erro.finalizar.titulo",
                "Erro ao finalizar atividade",
              ),
              mensagem: i18n.message(
                "dinamica.presencial.checkin.qrcode.formato.invalido",
                "QR code em formato inválido",
              ),
              voltarAgenda: false,
            });
            return;
          } else {
            checkUrl(code.data);
            return;
          }
        }
        const conteudoQRCode = JSON.parse(code.data);
        if (!conteudoQRCode.code) {
          setErro({
            titulo: i18n.message(
              "dinamica.erro.finalizar.titulo",
              "Erro ao finalizar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.presencial.checkin.qrcode.invalido",
              "QR code inválido",
            ),
            voltarAgenda: false,
          });
          return;
        }

        if (usarGeolocalizacao === true && (!latitude || !longitude)) {
          setErro({
            titulo: i18n.message(
              "dinamica.erro.finalizar.titulo",
              "Erro ao finalizar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.erro.checkin.localizacao",
              "Erro ao obter as coordenadas para o checkin. Entre em contato com o suporte ou tente novamente mais tarde.",
            ),
            voltarAgenda: false,
          });
          return;
        }

        if (
          !!conteudoQRCode?.code &&
          codigoAtividade !== conteudoQRCode?.code
        ) {
          setErro({
            titulo: i18n.message(
              "dinamica.erro.finalizar.titulo",
              "Erro ao finalizar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.erro.checkin.codigo",
              "Código de checkin inválido",
            ),
            voltarAgenda: false,
          });
          return;
        }

        const body = {
          code: conteudoQRCode.code,
          position: {
            latitude: latitude,
            longitude: longitude,
          },
        };
        dinamicaPresencialService.fazerCheckin(
          campaignCode,
          body,
          (erro, sucesso) => {
            if (sucesso) {
              finalizarAcao(sucesso);
            }
            if (erro) {
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.finalizar.titulo",
                  "Erro ao finalizar atividade",
                ),
                // eslint-disable-next-line max-len
                mensagem: `${erro.response.data.message} Localização com latitude: ${latitude},longitude: ${longitude}`,
                voltarAgenda: false,
              });
              setProcessandoQRCode(false);
              setTimeout(() => {
                requestAnimationFrame(tick);
              }, 1000);
            }
          },
        );
      } else {
        requestAnimationFrame(tick);
      }
    } else {
      requestAnimationFrame(tick);
    }
  };

  const pedirAcessoACamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        if (videoRef && videoRef.current) {
          let video = videoRef.current;
          video.srcObject = stream;
          video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          video.play();
          setVideoPronto(true);
        }
      })
      .catch((error) => {
        setErro({
          titulo: i18n.message(
            "permissao.acesso.camera.erro.titulo",
            "Erro ao solicitar acesso a câmera",
          ),
          mensagem: error.message,
          voltarAgenda: false,
        });
      });
  };

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 9000,
      maximumAge: 100,
    };

    function localizacaoObtida(pos) {
      if (pos.coords.latitude && pos.coords.longitude) {
        setLatitude(pos.coords.latitude);
        setLongitude(pos.coords.longitude);
        // Use facingMode: environment to attemt to get the front camera on phones
        pedirAcessoACamera();
      } else {
        setErro({
          titulo: i18n.message(
            "permissao.acesso.localizacao.erro.titulo",
            "Erro ao solicitar acesso a localização",
          ),
          mensagem: i18n.message(
            "permissao.acesso.localizacao.erro.mensagem",
            "Localização indisponível",
          ),
          voltarAgenda: false,
        });
      }
    }

    function errorObterLolizacao(err) {
      let mensagemErro;
      console.error(err);
      switch (err.code) {
        // Permissão negada
        case err.PERMISSION_DENIED:
          mensagemErro = i18n.message(
            "permissao.acesso.localizacao.negada.mensagem",
            "Acesso à localização negado pelo usuário. Para fazer o check-in libere o acesso ao gps do dispositivo.",
          );
          break;
        // posição indisponivel
        case err.POSITION_UNAVAILABLE:
          mensagemErro = i18n.message(
            "localizacao.indisponivel.mensagem",
            "Informações de localização indisponíveis.",
          );
          break;
        // Tempo limite excedido
        case err.TIMEOUT:
          mensagemErro = i18n.message(
            "localizacao.timeout.mensagem",
            "Tempo limite para obter localização excedido.",
          );
          break;
        default:
          // Mensagem padrão para outros tipos de erros
          mensagemErro = i18n.message(
            "erro.localizacao.desconhecido.mensagem",
            "Erro ao obter localização.",
          );
      }

      setErro({
        titulo: i18n.message(
          "permissao.acesso.localizacao.erro.titulo",
          "Erro ao solicitar acesso a localização",
        ),
        mensagem: mensagemErro,
        voltarAgenda: false,
      });
    }

    if (usarGeolocalizacao) {
      navigator.geolocation.getCurrentPosition(
        localizacaoObtida,
        errorObterLolizacao,
        options,
      );
    } else {
      pedirAcessoACamera();
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNaN(latitude) && !isNaN(longitude) && videoPronto) {
      requestAnimationFrame(tick);
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, videoPronto]);

  return (
    <>
      {erro != null && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <canvas style={videoStyle} id="canvas" hidden ref={canvasRef}></canvas>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video id="video" hidden ref={videoRef}></video>
    </>
  );
}
