import packageInfo from "../../package.json";
import CampanhaService from "../services/CampanhaService";
import UsuarioService from "../services/UsuarioService";
import instance from "./Api";
import PwaService from "./PwaService";
import activitiesConfig from "../comps/config/activities.json";

export default class AgendaService {
  usuarioService = new UsuarioService();
  usuarioCorrente = this.usuarioService.usuarioCorrente();
  adminOrGestor =
    this.usuarioService.isAdmin() || this.usuarioService.isGestor();
  pwaService = new PwaService();
  campanhaService = new CampanhaService();

  agendaCorrente(callback) {
    this.cacheValido((erro, valido) => {
      if (valido.campaign === true) {
        callback(null, this.obterAgendaStorage());
      } else {
        if (this.pwaService.estouOnline()) {
          if (!this.usuarioCorrente) {
            this.usuarioService.logout(() => {
              callback(true, null);
            });
            return;
          }

          this.buscarAgenda(callback);
        } else {
          callback(null, this.obterAgendaStorage());
        }
      }
    });
  }

  buscarAgenda(callback) {
    instance
      .get(
        this.host() +
          "/api/v1/participant/" +
          this.usuarioCorrente.participantCode +
          "/schedule",
      )
      .then((response) => {
        this.setAgenda(response.data);
        window.localStorage.setItem(
          "validade-cache-agenda",
          new Date().getTime() + 2 * 60 * 60 * 1000,
        );
        callback(null, response.data);
      })
      .catch((error) => {
        if (error.status !== 401 && error.status !== 403) {
          callback(null, this.obterAgendaStorage());
        }
      });
  }

  agendaCorrentePromise() {
    window.localStorage.removeItem("agenda");
    return new Promise((resolve, reject) => {
      this.buscarAgenda((error, agenda) => {
        if (error) {
          reject(error);
        } else {
          resolve(agenda);
        }
      });
    });
  }

  invalidateCacheAndReload() {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  }

  obterAgendaStorage() {
    let valorStorage = window.localStorage.getItem("agenda");
    if (valorStorage) {
      return JSON.parse(valorStorage);
    } else {
      return null;
    }
  }

  setAgenda(agenda) {
    if (agenda) {
      window.localStorage.setItem("agenda", JSON.stringify(agenda));
    }
  }

  versaoAlterada(callback) {
    instance
      .get(
        this.host() +
          "/api/v1/weex/info?campaign=" +
          this.campanhaService.campanhaCorrente().uuid,
      )
      .then((response) => {
        window.localStorage.setItem(
          "validade-cache-agenda",
          new Date().getTime() + 30 * 60 * 1000,
        );
        let versoes = {};
        versoes.campaign = !(
          (response.data.campaign === null &&
            this.campanhaService.campanhaCorrente().version === null) ||
          (response.data.campaign != null &&
            this.campanhaService.campanhaCorrente().version !== null &&
            response.data.campaign ===
              this.campanhaService.campanhaCorrente().version)
        );
        versoes.pwa = !(
          response.data.pwa === null ||
          response.data.pwa === packageInfo.version
        );

        callback(null, versoes);
      })
      .catch((error) => {
        if (error.status !== 401 && error.status !== 403) {
          callback(null, { campaign: false, pwa: false });
        }
      });
  }

  cacheValido(callback) {
    if (this.obterAgendaStorage()) {
      let valorStorage = window.localStorage.getItem("validade-cache-agenda");
      if (valorStorage) {
        let valido = parseInt(valorStorage) > new Date().getTime();
        if (!valido) {
          this.versaoAlterada((erro, versoes) => {
            callback(null, this.preencheCacheValido(versoes));
          });
        } else {
          callback(null, { campaign: true, pwa: true });
        }
      } else {
        this.versaoAlterada((erro, versoes) => {
          callback(null, this.preencheCacheValido(versoes));
        });
      }
    } else {
      callback(null, { campaign: false, pwa: true });
    }
  }

  atividadesPorSubtitulos(subtitles, agendaCorrente) {
    return subtitles.map((subtitle) => {
      let bySubtitle = {
        subtitle: subtitle,
        activities: agendaCorrente.activities.filter(
          (activity) =>
            activity.subtitle.uuid === subtitle.uuid &&
            activity.type !== "GAME",
        ),
      };
      return bySubtitle;
    });
  }

  atualizarAgendaGame(codigo, codigoAtividade, atividade, callback) {
    let agenda = this.obterAgendaStorage();
    if (!agenda) {
      //não deveria ocorrer
      this.agendaCorrente(callback);
      return;
    }
    for (let i = 0; i < agenda.schedule.length; i++) {
      if (agenda.schedule[i].scheduleCode === codigo) {
        for (let j = 0; j < agenda.schedule[i].activities.length; j++) {
          if (
            agenda.schedule[i].activities[j].type === atividade &&
            agenda.schedule[i].activities[j].uuid === codigoAtividade
          ) {
            agenda.schedule[i].activities[j].executed = true;

            this.setAgenda(agenda);
            callback(null, true);
            return;
          }
        }
        callback(true, null);
        return;
      }
    }
    callback(true, null);
  }

  atualizarAgenda(
    codigo,
    codigoAtividade,
    atividade,
    numeroQuestoes,
    acertos,
    callback,
  ) {
    let agenda = this.obterAgendaStorage();
    if (!agenda) {
      //não deveria ocorrer
      this.agendaCorrente(callback);
      return;
    } else if (atividade === "GAME") {
      this.atualizarAgendaGame(codigo, codigoAtividade, atividade, callback);
      return;
    }
    for (let i = 0; i < agenda.schedule.length; i++) {
      const hasSubtitles = agenda.schedule[i].subtitles?.length > 0;
      const subtitles = [...agenda.schedule[i].subtitles];
      if (agenda.schedule[i].scheduleCode === codigo) {
        if (hasSubtitles) {
          let activitiesWithSubtitles = this.atividadesPorSubtitulos(
            subtitles,
            agenda.schedule[i],
          );
          for (let j = 0; j < activitiesWithSubtitles.length; j++) {
            const bySubtitle = activitiesWithSubtitles[j];
            for (let k = 0; k < bySubtitle.activities.length; k++) {
              const activity = activitiesWithSubtitles[j].activities[k];
              if (
                activity.type === atividade &&
                activity.uuid === codigoAtividade
              ) {
                if (atividade === "FACT_OR_FAKE") {
                  activity.numeroQuestoes = numeroQuestoes;
                  activity.acertos = acertos;
                } else {
                  activity.executed = true;
                }
                this.nextAtividadeBySubtitle(
                  agenda,
                  activitiesWithSubtitles,
                  i,
                  j,
                  k,
                );
                this.setAgenda(agenda);
                callback(null, true);
                return;
              }
            }
          }
          callback(true, null);
          return;
        } else {
          const activitiesWithoutGames = agenda.schedule[i].activities.filter(
            (atv) => atv.type !== "GAME",
          );
          for (let j = 0; j < activitiesWithoutGames.length; j++) {
            if (
              activitiesWithoutGames[j].type === atividade &&
              activitiesWithoutGames[j].uuid === codigoAtividade
            ) {
              if (atividade === "FACT_OR_FAKE") {
                activitiesWithoutGames[j].numeroQuestoes = numeroQuestoes;
                activitiesWithoutGames[j].acertos = acertos;
              } else {
                activitiesWithoutGames[j].executed = true;
              }
              if (
                this.hasAgendaDesbloqueada(agenda.schedule[i].day) ||
                this.adminOrGestor
              ) {
                this.nextAtividade(agenda, activitiesWithoutGames, i, j);
              }
              this.setAgenda(agenda);
              callback(null, true);
              return;
            }
          }
          callback(true, null);
          return;
        }
      }
    }
    callback(true, null);
  }

  nextAtividadeBySubtitle(agenda, activitiesWithSubtitles, i, j, k) {
    // Verifica próxima atividade dentro do subtitulo atual
    if (k < activitiesWithSubtitles[j].activities.length - 1) {
      const next = activitiesWithSubtitles[j].activities[k + 1];
      const { route } = activitiesConfig.find((a) => a.type === next.type);
      const scheduleCode = agenda.schedule[i].scheduleCode;
      const uri = `/${route}/${scheduleCode}/${next.uuid}`;
      activitiesWithSubtitles[j].activities[k].nextActivity = uri;
      activitiesWithSubtitles[j].activities[k].nextActivityExecuted =
        next.executed;
    } else if (
      j < activitiesWithSubtitles.length - 1 &&
      activitiesWithSubtitles[j + 1].activities.length > 0
    ) {
      // Verifica primeira atividade no próximo subtitulo
      const next = activitiesWithSubtitles[j + 1].activities[0];
      if (next) {
        const { route } = activitiesConfig.find((a) => a.type === next.type);
        const scheduleCode = agenda.schedule[i].scheduleCode;
        const uri = `/${route}/${scheduleCode}/${next.uuid}`;
        activitiesWithSubtitles[j].activities[k].nextActivity = uri;
        activitiesWithSubtitles[j].activities[k].nextActivityExecuted =
          next.executed;
      }
    } else if (
      i < agenda.schedule.length - 1 &&
      agenda.schedule[i + 1].activities.length > 0 &&
      this.hasAgendaDesbloqueada(
        agenda.schedule[i + 1].day || this.adminOrGestor,
      )
    ) {
      // Verifica primeria atividade no primeiro subtitulo da próxima agenda
      const hasSubtitles = agenda.schedule[i + 1].subtitles?.length > 0;
      let next = null;
      // Verifica se a próxima agenda tem subtitulos e pegar a primeira atividades
      // se não pegar a primerira atividade da agenda sem considerar os subtitulos
      if (hasSubtitles) {
        const subtitles = [...agenda.schedule[i + 1].subtitles];
        let activitiesWithSubtitles = this.atividadesPorSubtitulos(
          subtitles,
          agenda.schedule[i + 1],
        );
        next = activitiesWithSubtitles[0].activities[0];
      } else {
        let nextActivitiesWithoutGames = agenda.schedule[
          i + 1
        ].activities.filter((atv) => atv.type !== "GAME");
        next = nextActivitiesWithoutGames[0];
      }
      const { route } = activitiesConfig.find((a) => a.type === next.type);
      const scheduleCode = agenda.schedule[i + 1].scheduleCode;
      const uri = `/${route}/${scheduleCode}/${next.uuid}`;
      activitiesWithSubtitles[j].activities[k].nextActivity = uri;
      activitiesWithSubtitles[j].activities[k].nextActivityExecuted =
        next.executed;
    } else {
      // Se a próxima atividade não for desbloqueada, limpar os campos
      activitiesWithSubtitles[j].activities[k].nextActivity = null;
      activitiesWithSubtitles[j].activities[k].nextActivityExecuted = null;
    }
  }

  nextAtividade(agenda, activitiesWithoutGames, i, j) {
    // Verifica próxima atividade dentro da agenda atual
    if (j < activitiesWithoutGames.length - 1) {
      const next = activitiesWithoutGames[j + 1];
      const { route } = activitiesConfig.find((a) => a.type === next.type);
      const scheduleCode = agenda.schedule[i].scheduleCode;
      const uri = `/${route}/${scheduleCode}/${next.uuid}`;
      activitiesWithoutGames[j].nextActivity = uri;
      activitiesWithoutGames[j].nextActivityExecuted = next.executed;
    } else if (
      i < agenda.schedule.length - 1 &&
      agenda.schedule[i + 1].activities.length > 0 &&
      (this.hasAgendaDesbloqueada(agenda.schedule[i + 1].day) ||
        this.adminOrGestor)
    ) {
      // Verifica primeira atividade na próxima agenda
      let nextActivitiesWithoutGames = agenda.schedule[i + 1].activities.filter(
        (atv) => atv.type !== "GAME",
      );
      let next = null;
      const hasSubtitles = agenda.schedule[i + 1].subtitles?.length > 0;

      // Verifica se a próxima agenda tem subtitulos e pegar a primeira atividades
      // se não pegar a primerira atividade da agenda sem considerar os subtitulos
      if (hasSubtitles) {
        const subtitles = [...agenda.schedule[i + 1].subtitles];
        let activitiesWithSubtitles = this.atividadesPorSubtitulos(
          subtitles,
          agenda.schedule[i + 1],
        );
        next = activitiesWithSubtitles[0].activities[0];
      } else {
        next = nextActivitiesWithoutGames[0];
      }
      const { route } = activitiesConfig.find((a) => a.type === next.type);
      const scheduleCode = agenda.schedule[i + 1].scheduleCode;
      const uri = `/${route}/${scheduleCode}/${next.uuid}`;
      activitiesWithoutGames[j].nextActivity = uri;
      activitiesWithoutGames[j].nextActivityExecuted = next.executed;
    } else {
      // Se a próxima atividade não for desbloqueada, limpar os campos
      activitiesWithoutGames[j].nextActivity = null;
      activitiesWithoutGames[j].nextActivityExecuted = null;
    }
  }

  /**
   * retornar true se a agenda é do dia atual ou anterior
   * */
  hasAgendaDesbloqueada(dateString) {
    try {
      const [year, month, day] = dateString.split("-").map(Number);
      const diaAgenda = new Date(year, month - 1, day);

      const today = new Date();
      return diaAgenda <= today;
    } catch (error) {
      console.error("Erro ao analisar a data:", error);
      return false;
    }
  }

  atualizarInteresse(interesse) {
    let valorStorage = window.localStorage.getItem("agenda");
    let valorStorageValidade = window.localStorage.getItem(
      "validade-cache-agenda",
    );
    if (interesse === true && valorStorage && valorStorageValidade) {
      window.localStorage.removeItem("agenda");
      window.localStorage.removeItem("validade-cache-agenda");
    }
  }

  preencheCacheValido(versoesAlteradas) {
    let cacheValido = { campaign: true, pwa: true };
    if (versoesAlteradas.campaign != null) {
      cacheValido.campaign = !versoesAlteradas.campaign;
    } else {
      cacheValido.campaign = false;
    }
    if (versoesAlteradas.pwa != null) {
      cacheValido.pwa = !versoesAlteradas.pwa;
    } else {
      cacheValido.pwa = false;
    }
    return cacheValido;
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }

  atualizarAtividadeAgendaExecutada(codigoAtividade, executada) {
    let agenda = this.obterAgendaStorage();
    let atividadeEncontrada = agenda?.schedule
      .flatMap((dia) => dia.activities)
      .find((atividade) => atividade.uuid === codigoAtividade);

    if (atividadeEncontrada) {
      atividadeEncontrada.executed = executada;
    }
    this.setAgenda(agenda);
  }
}
